// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduleCounter-module__LknLKMTrrkt3UE28MAqt {\n  font-size: 12px;\n  line-height: 16px;\n}\n\n.ScheduleCounter-module__VUcM3Ap2stWOpoVUFK96 {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.ScheduleCounter-module__auuki6JtdzCQC00S0mkQ {\n  padding: 2px 4px;\n  background: rgba(245, 183, 61, 0.18);\n  border: 1px solid var(--warning-text-color);\n  border-radius: 2px;\n}\n\n.ScheduleCounter-module__R8vTHjfzMbxoVYY2C7BP {\n  color: var(--success-text-color);\n}\n\n.ScheduleCounter-module__LgdqbGhVpJDQ5GkX2O4n {\n  color: var(--warning-text-color);\n}\n\n.ScheduleCounter-module__Od7RuwA5sm9jIYjeY6gV {\n  width: auto;\n}\n", "",{"version":3,"sources":["webpack://./components/ScheduleCounter/ScheduleCounter.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;EACnC,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,oCAAoC;EACpC,2CAA2C;EAC3C,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  font-size: 12px;\n  line-height: 16px;\n}\n\n.root__type_link {\n  padding: 2px 4px;\n  background: rgba(27, 133, 94, 0.15);\n  border: 1px solid var(--success-text-color);\n  border-radius: 2px;\n}\n\n.root__type_warning {\n  padding: 2px 4px;\n  background: rgba(245, 183, 61, 0.18);\n  border: 1px solid var(--warning-text-color);\n  border-radius: 2px;\n}\n\n.icon__type_link {\n  color: var(--success-text-color);\n}\n\n.icon__type_warning {\n  color: var(--warning-text-color);\n}\n\n.tooltip {\n  width: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "ScheduleCounter-module__LknLKMTrrkt3UE28MAqt",
	"root__type_link": "ScheduleCounter-module__VUcM3Ap2stWOpoVUFK96",
	"root__type_warning": "ScheduleCounter-module__auuki6JtdzCQC00S0mkQ",
	"icon__type_link": "ScheduleCounter-module__R8vTHjfzMbxoVYY2C7BP",
	"icon__type_warning": "ScheduleCounter-module__LgdqbGhVpJDQ5GkX2O4n",
	"tooltip": "ScheduleCounter-module__Od7RuwA5sm9jIYjeY6gV"
};
export default ___CSS_LOADER_EXPORT___;
