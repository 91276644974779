// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserWarning-module__NTUnTn290_otu_T7gDQ3{margin:16px}.UserWarning-module__DxUvCxQDpors8rCbguwx{list-style-type:none;margin-left:23px;width:100%}.UserWarning-module__DxUvCxQDpors8rCbguwx>li{width:100%;background:var(--background-secondary);margin-bottom:4px;padding:14px 12px}.UserWarning-module__qcLCIMrBKTjm65fUvJZW{width:8px;height:8px;border-radius:50%;background:#6ccf8e}", "",{"version":3,"sources":["webpack://./containers/UserWarningModal/UserWarning.module.scss"],"names":[],"mappings":"AAAA,0CACE,WAAA,CAGF,0CACE,oBAAA,CACA,gBAAA,CACA,UAAA,CAEA,6CACE,UAAA,CACA,sCAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,0CACE,SAAA,CACA,UAAA,CACA,iBAAA,CACA,kBAAA","sourcesContent":[".user-warning {\n  margin: 16px;\n}\n\n.users {\n  list-style-type: none;\n  margin-left: 23px;\n  width: 100%;\n\n  & > li {\n    width: 100%;\n    background: var(--background-secondary);\n    margin-bottom: 4px;\n    padding: 14px 12px;\n  }\n}\n.dot {\n  width: 8px;\n  height: 8px;\n  border-radius: 50%;\n  background: #6ccf8e;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-warning": "UserWarning-module__NTUnTn290_otu_T7gDQ3",
	"users": "UserWarning-module__DxUvCxQDpors8rCbguwx",
	"dot": "UserWarning-module__qcLCIMrBKTjm65fUvJZW"
};
export default ___CSS_LOADER_EXPORT___;
