// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Integrations-module__CQosY2mhOHVuNXCEN_QU {\n  margin-bottom: 20px;\n}\n\n.Integrations-module__EiQGEV1etQ9QizS76Jq4 {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  border: var(--border);\n  border-radius: 2px;\n}\n\n.Integrations-module__kI7cqOLxCmFMN5BPK4bq {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.Integrations-module__ascL_2QHO_Jnmfshu4Ww {\n  width: 400px;\n  flex-shrink: 0;\n  overflow: auto;\n  max-height: 70vh;\n}\n\n.Integrations-module__LoDstTMhx9pseCXlBaKJ {\n  margin: 16px;\n  flex-grow: 1;\n}\n\n.Integrations-module__ESj2ZmDOVhwGxEa1fDXk {\n  margin: 16px;\n}\n\n.Integrations-module__lDAUOhpu4ompToJyTwQe {\n  border-right: var(--border);\n}\n", "",{"version":3,"sources":["webpack://./pages/integrations/Integrations.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;EACvB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":[".filters {\n  margin-bottom: 20px;\n}\n\n.integrations {\n  width: 100%;\n  display: flex;\n  align-items: flex-start;\n  border: var(--border);\n  border-radius: 2px;\n}\n\n.no-integrations {\n  width: 100%;\n  padding: 50px 0;\n}\n\n.alert-receive-channels-list {\n  width: 400px;\n  flex-shrink: 0;\n  overflow: auto;\n  max-height: 70vh;\n}\n\n.alert-rules {\n  margin: 16px;\n  flex-grow: 1;\n}\n\n.newIntegrationButton {\n  margin: 16px;\n}\n\n.integrationsList {\n  border-right: var(--border);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filters": "Integrations-module__CQosY2mhOHVuNXCEN_QU",
	"integrations": "Integrations-module__EiQGEV1etQ9QizS76Jq4",
	"no-integrations": "Integrations-module__kI7cqOLxCmFMN5BPK4bq",
	"alert-receive-channels-list": "Integrations-module__ascL_2QHO_Jnmfshu4Ww",
	"alert-rules": "Integrations-module__LoDstTMhx9pseCXlBaKJ",
	"newIntegrationButton": "Integrations-module__ESj2ZmDOVhwGxEa1fDXk",
	"integrationsList": "Integrations-module__lDAUOhpu4ompToJyTwQe"
};
export default ___CSS_LOADER_EXPORT___;
