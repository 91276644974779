// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Schedule-module__C8BiwZPUejqSfRqFyCQb {\n  --rotations-border: var(--border-weak);\n  --rotations-background: var(--background-secondary);\n}\n\n.Schedule-module__N3nQcAcIls98Jw9XyTzi {\n  position: sticky; /* TODO check */\n  width: 100%;\n}\n\n.Schedule-module__Ufl1makMfF9A2tEg7Hst {\n  width: 736px;\n}\n\n.Schedule-module__TsviDhBP_otxanR2ukKC {\n  width: 100%;\n}\n\n.Schedule-module__v2C6cAtELzjUX5qrB7VO {\n  width: 100%;\n}\n\n.Schedule-module__N1tV87CJOYvZ8IIGBc3L {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  position: relative;\n  width: 100%;\n}\n", "",{"version":3,"sources":["webpack://./pages/schedule/Schedule.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,mDAAmD;AACrD;;AAEA;EACE,gBAAgB,EAAE,eAAe;EACjC,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".root {\n  --rotations-border: var(--border-weak);\n  --rotations-background: var(--background-secondary);\n}\n\n.header {\n  position: sticky; /* TODO check */\n  width: 100%;\n}\n\n.desc {\n  width: 736px;\n}\n\n.users-timezones {\n  width: 100%;\n}\n\n.controls {\n  width: 100%;\n}\n\n.rotations {\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  position: relative;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Schedule-module__C8BiwZPUejqSfRqFyCQb",
	"header": "Schedule-module__N3nQcAcIls98Jw9XyTzi",
	"desc": "Schedule-module__Ufl1makMfF9A2tEg7Hst",
	"users-timezones": "Schedule-module__TsviDhBP_otxanR2ukKC",
	"controls": "Schedule-module__v2C6cAtELzjUX5qrB7VO",
	"rotations": "Schedule-module__N1tV87CJOYvZ8IIGBc3L"
};
export default ___CSS_LOADER_EXPORT___;
