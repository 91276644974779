// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal-module__Dv5ELyrxq_szvD5XiWyL {\n  position: fixed;\n  width: 750px;\n  max-width: 100%;\n  left: 0;\n  right: 0;\n  margin-left: auto;\n  margin-right: auto;\n  top: 10%;\n  max-height: 80%;\n  display: flex;\n  flex-direction: column;\n  border-image: initial;\n  outline: none;\n  padding: 15px;\n  background: var(--background-primary);\n  border: var(--border-weak);\n  box-shadow: var(--shadows-z3);\n  border-radius: 2px;\n  z-index: 10;\n}\n\n/*\n\n.overlay {\n  position: relative;\n  inset: 0;\n  z-index: 10;\n  background-color: rgba(0, 0, 0, 0.45);\n  backdrop-filter: blur(1px);\n}\n\n.body-open {\n  overflow: hidden;\n}\n\n */\n", "",{"version":3,"sources":["webpack://./components/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,YAAY;EACZ,eAAe;EACf,OAAO;EACP,QAAQ;EACR,iBAAiB;EACjB,kBAAkB;EAClB,QAAQ;EACR,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,aAAa;EACb,aAAa;EACb,qCAAqC;EACrC,0BAA0B;EAC1B,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;AACb;;AAEA;;;;;;;;;;;;;;EAcE","sourcesContent":[".root {\n  position: fixed;\n  width: 750px;\n  max-width: 100%;\n  left: 0;\n  right: 0;\n  margin-left: auto;\n  margin-right: auto;\n  top: 10%;\n  max-height: 80%;\n  display: flex;\n  flex-direction: column;\n  border-image: initial;\n  outline: none;\n  padding: 15px;\n  background: var(--background-primary);\n  border: var(--border-weak);\n  box-shadow: var(--shadows-z3);\n  border-radius: 2px;\n  z-index: 10;\n}\n\n/*\n\n.overlay {\n  position: relative;\n  inset: 0;\n  z-index: 10;\n  background-color: rgba(0, 0, 0, 0.45);\n  backdrop-filter: blur(1px);\n}\n\n.body-open {\n  overflow: hidden;\n}\n\n */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Modal-module__Dv5ELyrxq_szvD5XiWyL"
};
export default ___CSS_LOADER_EXPORT___;
