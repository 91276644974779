// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput-module__HhoullpY0Yk7x87zUa1g{display:inline-flex;align-items:center}.SearchInput-module__HhoullpY0Yk7x87zUa1g .SearchInput-module__Gb63_zZnXIhkeX2QLL0U{width:320px}", "",{"version":3,"sources":["webpack://./components/SearchInput/SearchInput.module.scss"],"names":[],"mappings":"AAAA,0CACE,mBAAA,CACA,kBAAA,CAEA,oFACE,WAAA","sourcesContent":[".root {\n  display: inline-flex;\n  align-items: center;\n\n  & .search {\n    width: 320px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "SearchInput-module__HhoullpY0Yk7x87zUa1g",
	"search": "SearchInput-module__Gb63_zZnXIhkeX2QLL0U"
};
export default ___CSS_LOADER_EXPORT___;
