// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/integration-logos.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../assets/img/PagerDuty.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../assets/img/ElastAlert.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../assets/img/HeartBeatMonitoring.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../../assets/img/grafana_icon.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationLogo-module__qASLBNnnIXTzY3BzAl3K {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n\n.IntegrationLogo-module__Lbc5X8nSJWYGEPG0nI2z {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__FJDByw9FKFAZ0qThiEXR {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__Qww6BfhQeP3B7zTPktTw {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-size: 100% !important;\n}\n\n.IntegrationLogo-module__CDbvXILgBbJew3a1JvtM,\n.IntegrationLogo-module__ozXnoCVLlcxo_G0_mzcA {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");\n  background-size: 100% !important;\n}\n", "",{"version":3,"sources":["webpack://./components/IntegrationLogo/IntegrationLogo.module.css"],"names":[],"mappings":"AAAA;EACE,mDAAuD;EACvD,4BAA4B;AAC9B;;AAEA;EACE,mDAA+C;EAC/C,gCAAgC;AAClC;;AAEA;EACE,mDAAgD;EAChD,gCAAgC;AAClC;;AAEA;EACE,mDAAyD;EACzD,gCAAgC;AAClC;;AAEA;;EAEE,mDAAkD;EAClD,gCAAgC;AAClC","sourcesContent":[".bg {\n  background: url(../../assets/img/integration-logos.png);\n  background-repeat: no-repeat;\n}\n\n.bg_PagerDuty {\n  background: url(../../assets/img/PagerDuty.png);\n  background-size: 100% !important;\n}\n\n.bg_ElastAlert {\n  background: url(../../assets/img/ElastAlert.svg);\n  background-size: 100% !important;\n}\n\n.bg_HeartBeatMonitoring {\n  background: url(../../assets/img/HeartBeatMonitoring.png);\n  background-size: 100% !important;\n}\n\n.bg_Grafana,\n.bg_GrafanaAlerting {\n  background: url(../../assets/img/grafana_icon.svg);\n  background-size: 100% !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bg": "IntegrationLogo-module__qASLBNnnIXTzY3BzAl3K",
	"bg_PagerDuty": "IntegrationLogo-module__Lbc5X8nSJWYGEPG0nI2z",
	"bg_ElastAlert": "IntegrationLogo-module__FJDByw9FKFAZ0qThiEXR",
	"bg_HeartBeatMonitoring": "IntegrationLogo-module__Qww6BfhQeP3B7zTPktTw",
	"bg_Grafana": "IntegrationLogo-module__CDbvXILgBbJew3a1JvtM",
	"bg_GrafanaAlerting": "IntegrationLogo-module__ozXnoCVLlcxo_G0_mzcA"
};
export default ___CSS_LOADER_EXPORT___;
